import { Injectable, computed, inject, signal } from '@angular/core';
import {
  Firestore,
  collection,
  collectionData,
  query,
  where,
  doc,
  updateDoc,
  addDoc,
  getDoc,
  getDocs,
} from '@angular/fire/firestore';
import {
  Country,
  EstablishmentLogisticsProvider,
  LogisticsProviderName,
} from '@pedix-workspace/utils';
import { Observable, map } from 'rxjs';

export interface LogisticsProviderItem {
  key: LogisticsProviderName;
  name: string;
  description: string;
  logo: string;
  countryAvailability: Country[];
  url: string;
}

export const LOGISTIC_PROVIDER_PUNIYA: LogisticsProviderItem = {
  key: 'puniya',
  name: 'Puniya',
  description: 'Integración con Puniya Delivery',
  logo: 'puniya.webp',
  countryAvailability: ['AR'],
  url: 'https://puniya.ar/puniya',
};
// export const LOGISTIC_PROVIDER_PEDIDOS_YA: LogisticsProviderItem = {
//   key: 'pedidosya',
//   name: 'PedidosYa',
//   description: 'Integración con PedidosYa Delivery',
//   logo: 'pedidosya.webp',
//   countryAvailability: ['AR', 'UY', 'CL'],
// };
export const LOGISTIC_PROVIDER_CABIFY: LogisticsProviderItem = {
  key: 'cabify',
  name: 'Cabify',
  description: 'Integración con Cabify',
  logo: 'cabify.webp',
  countryAvailability: ['AR', 'CL', 'MX'],
  url: 'https://cabify.com.ar',
};
export const LOGISTIC_PROVIDER_RAPIBOY: LogisticsProviderItem = {
  key: 'rapiboy',
  name: 'Rapiboy',
  description: 'Integración con Rapiboy',
  logo: 'rapiboy.webp',
  countryAvailability: ['AR'],
  url: 'https://rapiboy.com/Global/LandingReferidopedix',
};
export const LOGISTIC_PROVIDER_CORREO_ARGENTINO: LogisticsProviderItem = {
  key: 'correoargentino',
  name: 'Correo Argentino',
  description: 'Integración con Correo Argentino',
  logo: 'correoargentino.webp',
  countryAvailability: ['AR'],
  url: 'https://correoargentino.com.ar',
};

@Injectable({
  providedIn: 'root',
})
export class LogisticsIntegrationsService {
  private firestore = inject(Firestore);
  readonly #currentProvider = signal<EstablishmentLogisticsProvider | null>(null);

  get currentProvider() {
    return this.#currentProvider();
  }

  setCurrentProvider(provider: EstablishmentLogisticsProvider) {
    this.#currentProvider.set(provider);
  }

  async getCurrentProvider(
    establishmentId: string,
    providerName: LogisticsProviderName,
  ): Promise<EstablishmentLogisticsProvider> {
    if (this.#currentProvider()) {
      return this.#currentProvider();
    }

    const providersRef = collection(this.firestore, 'establishmentLogisticsProvider');
    const q = query(
      providersRef,
      where('establishmentId', '==', establishmentId),
      where('providerName', '==', providerName),
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const providerData = querySnapshot.docs[0].data() as EstablishmentLogisticsProvider;
      providerData.id = querySnapshot.docs[0].id;
      return providerData;
    } else {
      return this.createEmptyProvider(providerName, establishmentId);
    }
  }

  getLogisticsProvidersForEstablishment(
    establishmentId: string,
  ): Observable<EstablishmentLogisticsProvider[]> {
    const providersRef = collection(this.firestore, 'establishmentLogisticsProvider');
    const q = query(providersRef, where('establishmentId', '==', establishmentId));

    return collectionData(q, { idField: 'id' }) as Observable<EstablishmentLogisticsProvider[]>;
  }

  createEmptyProvider(
    providerName: LogisticsProviderName,
    establishmentId: string,
  ): EstablishmentLogisticsProvider {
    return {
      establishmentId,
      providerName: providerName,
      isActive: false,
      configurations: {},
      createdAt: new Date(),
      updatedAt: new Date(),
      id: null,
    };
  }

  async saveProvider(provider: EstablishmentLogisticsProvider): Promise<void> {
    const providersRef = collection(this.firestore, 'establishmentLogisticsProvider');

    const updatedProvider: EstablishmentLogisticsProvider = {
      ...provider,
      updatedAt: new Date(),
      createdAt: provider.createdAt || new Date(),
    };

    if (provider.id) {
      const docRef = doc(providersRef, provider.id);
      await updateDoc(docRef, updatedProvider);
    } else {
      await addDoc(providersRef, updatedProvider);
    }
  }
}
